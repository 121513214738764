import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/en"
import Post from "../components/Blog/Post/post"
import SEO from '../components/seo'
import SideBar from "../components/Blog/Post/sidebar"


export const query = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag }, lang: { eq: "en" } } }
    ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
      }
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM DD, Y")
            tags
            image {
                childImageSharp {
                 fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
                  }
                }
              }
          }
          excerpt(pruneLength: 100)
          id
          timeToRead
          fields {
            slug
          }
        }
      }
    }
    site {
          siteMetadata {
            social_links {
              name
              link
            }
          }
        }
    }
`

const tags = ({ location, data: { allMarkdownRemark, site }, pageContext}) => {

  return (
    <Layout location={location}>
    <SEO title="Tags" description={'All posts about ' + pageContext.tag} />
    <div className="home-container tags">
    <section className="post-container">
      {allMarkdownRemark.edges.map(({ node }) => (
        <Post
        title={node.frontmatter.title}
        date={node.frontmatter.date}
        read={node.timeToRead}
        tags={node.frontmatter.tags}
        body={node.excerpt}
        image={node.frontmatter.image}
        slug={node.fields.slug}
        key={node.id}
        readText="read"
        />
      ))}
      </section>
      <SideBar
      social_links={site.siteMetadata.social_links}
      categories={allMarkdownRemark.group}
      topPosts={allMarkdownRemark.edges}
      />
      </div>
    </Layout>
  )
}

export default tags
